import {
  Box,
  Card,
  CardContent,
  FilledInput,
  FormControl,
  FormGroup,
  FormLabel,
  makeStyles,
  useTheme
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import componentStyles from "../../assets/theme/views/admin/profile.js";
import { withAppContext } from "../../contextApi/Auth";
import { BACKEND_URL } from "../../env";
import Loader from "../../components/Loader/loader.js";

const useStyles = makeStyles(componentStyles);

function CreateTags({
  createTags,
  open,
  handleClose,
  updateTags,
  setUpdateData,
  UpdateData,
  getTags
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [Loading, setLoading] = useState(false);
  const [Name, setName] = useState("");

  const clearState = () => {
    setName("");
    setUpdateData("");
  };
  const capitalizeText = (text) => {
    const firstLetter = text;
    const remainLetters = text.slice(1).toLowerCase();
    const first = firstLetter.charAt(0).toUpperCase();
    return first + remainLetters;
  };

  const handleCreateSubmit = (e) => {
    const Obj = {
      name: Name,
    };
    if (UpdateData) {
      // createTags(Obj, handleClose, clearState, UpdateData.id);
      if (Name?.length) {
        setLoading(true);
        const Obj = {
          name: Name
        };
        // console.log(Name);

        // const formData = new FormData();
        // formData.append('name', Name);
        const config = {
          method: "put",
          url: `${BACKEND_URL}/tag/${UpdateData?.id}`,
          data: Obj,
          headers: {
            token: localStorage.getItem("Token")
          }
        };
        axios(config)
          .then((res) => {
            getTags()
            setName(' ');
            setLoading(false);
            handleClose()
            console.log(res);
            toast.success(`Successfully update ${Name} tag`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          })
          .catch((res) => {
            setLoading(false);
            toast.error(`Invalid error`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          });
      } else {
        toast.error("Please Fill This tag field", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    } else {
      if (Name?.length) {
        setLoading(true);
        const Obj = {
          name: Name
        };
        // console.log(Name);

        // const formData = new FormData();
        // formData.append('name', Name);
        const config = {
          method: "post",
          url: `${BACKEND_URL}/tag`,
          data: Obj,
          headers: {
            token: localStorage.getItem("Token")
          }
        };
        axios(config)
          .then((res) => {
            getTags()
            setName(' ');
            setLoading(false);
            handleClose()
            console.log(res);
            toast.success(`Successfully added ${Name} tag`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          })
          .catch((res) => {
            setLoading(false);
            toast.error(`Invalid error`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          });
      } else {
        toast.error("Please Fill This tag field", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  };

  useEffect(() => {
    setName(UpdateData.name);
  }, [UpdateData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Tag
        </DialogTitle>
        <DialogContent>
          <Card classes={{ root: classes.cardRoot }}>
            <Box
              component={CardContent}
              classes={{ root: classes.ptMd4 }}
              paddingTop="0!important"
            >
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </FormGroup>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleCreateSubmit()}>
            {Loading ? <Loader cumtomStyle={{ position: "unset", }} /> : UpdateData ? "Update" : "Create"} Tag
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withAppContext(CreateTags);
