// @material-ui/icons components
import CategoryIcon from '@material-ui/icons/Category';
import StoreIcon from '@material-ui/icons/Store';
import LabelIcon from '@material-ui/icons/Label';
// core components
import CategoryTable from "./views/admin/Category";
import Products from "./views/admin/Products";
import SubCategory from "./views/admin/SubCategory";
import Login from "./views/auth/Login";
// import AddProduct from "./views/admin/CreateProduct";
import CreateProduct from "./views/admin/createProduct/CreateProduct";
import Tags from "./views/admin/Tags";
const routes = [
  {
    path: "/index",
    name: "Products",
    icon: StoreIcon,
    iconColor: "Error",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/Category",
    name: "Category",
    icon: CategoryIcon,
    iconColor: "Error",
    component: CategoryTable,
    layout: "/admin",
  },
  {
    path: "/SubCategory",
    name: "SubCategory",
    icon: CategoryIcon,
    iconColor: "Error",
    component: SubCategory,
    layout: "/admin",
  },
  {
    path: "/Tag",
    name: "Tags",
    icon: LabelIcon,
    iconColor: "Error",
    component: Tags,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/addProduct",
    component: CreateProduct,
    layout: "/admin",
  },
];
export default routes;
