import React from "react";
import "./fullViewLoader.css";
import Logo from "./desertsdroppshiper.png";
export default function FullViewLoader() {
  return (
    // <div className="fullViewLoader" id='fullViewLoader'>
    //   <Lottie
    //     className='InnerfullViewLoader'
    //     loop
    //     animationData={shoppingbag}
    //     play
    //     // style={{ width: 150, height: 150 }}
    //   />
    // </div>
    <div className="box">
      <img src={Logo} className="IMG" />
      <span className="loader-37"></span>
    </div>
  );
}
