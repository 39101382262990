import {
    Button
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { ImCancelCircle } from "react-icons/im";
import Loader from "../../../components/Loader/loader";
import SuspenseImg from "../../../components/suspenseImages/suspenseImg";
import "../TagStyle.css";
// import Dropzone from "react-dropzone";
// import sharp from 'sharp';


function StepThree({
    classes,
    productId,
    imageUpload,
    setImageUpload,
    madeImage,
    allImage,
    setAllImage,
    fileTypes,
    loading,
    addProductImages,
}) {
    const [webpImage, setWebpImage] = useState(null);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    return (
        <Card
            classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
            }}
        >
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignitem="center"
                        justifycontent="space-between"
                    >
                        <Grid item xs="auto">
                            <Box
                                component={Typography}
                                variant="h3"
                                marginBottom="0!important"
                            >
                                Enter Images
                            </Box>
                        </Grid>
                    </Grid>
                }
                // action={
                //     <Button
                //         variant="contained"
                //         size="small"
                //         classes={{ root: classes.buttonRootDark }}
                //         onClick={() => handleCreateSubmit()}
                //     >
                //         {loading ? <Loader /> : productId ? "Update" : "Create"}
                //     </Button>
                // }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            <CardContent>
                <Grid item xs={12} style={{
                    padding: "0px",

                }}>
                    <TabPanel >
                        <FormGroup>
                            <FormLabel>
                                <span style={{ color: "red" }}>
                                    Upload atleast 2 image for each
                                    picture
                                </span>
                            </FormLabel>
                            <div className="ImagesDiv">
                                {imageUpload?.map((b, i) => {
                                    return (
                                        <>
                                            <label className="imageUplaodlabel">
                                                <SuspenseImg
                                                    src={b.image}
                                                    type={b.type}
                                                />
                                                <div
                                                    className="cancelImage"
                                                    onClick={() => {
                                                        let arr = [...imageUpload];
                                                        let arrAll = [...allImage]
                                                        arr.splice(
                                                            imageUpload.indexOf(b),
                                                            1
                                                        );
                                                        arrAll.splice(
                                                            allImage.indexOf(b),
                                                            1
                                                        );
                                                        setImageUpload(arr);
                                                        setAllImage(arrAll);
                                                    }}
                                                >
                                                    <ImCancelCircle className="cancelImagIcon" />
                                                </div>
                                            </label>
                                        </>
                                    )
                                })}
                                <FileUploader
                                    className="imageUplaodlabel LabelImage"
                                    multiple={true}
                                    handleChange={(e) => {
                                        madeImage(e);
                                    }}
                                    name="file"
                                    // onDraggingStateChange={(dragging) =>
                                    //   console.log(dragging,"dragging")
                                    // }
                                    types={fileTypes}
                                />

                            </div>
                        </FormGroup>
                    </TabPanel>
                </Grid>
                <Button
                    style={{
                        width: "100%",
                        // margin: "0 1.5rem",
                        padding: "10px 20px",
                        marginBottom: "20px",
                    }}
                    variant="contained"
                    size="small"
                    className="addVariety"
                    classes={{ root: classes.buttonRootDark }}
                    onClick={() => addProductImages()}
                >
                    {loading ? <Loader /> : productId ? "Update Product Images & Next" : "Add Product Images & Next"}
                </Button>
            </CardContent>
        </Card >
    )
}
export default StepThree