import axios from "axios";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { BACKEND_URL } from "../../env";
import PurchaseReducer from "./Reducer";
// import { io } from "socket.io-client";

const initialState = {
	selected: [],
	Products: []
};

export const PurchaseContext = createContext();

export const withPurchaseContext = (Component) => (props) =>
(
	<PurchaseContext.Consumer>
		{(value) => <Component {...value} {...props} />}
	</PurchaseContext.Consumer>
);

const PurchaseProvider = ({ children }) => {
	const [state, dispatch] = useReducer(PurchaseReducer, initialState);
	const [ProductLoading, setProductLoading] = useState(true);

	function GetAllPurchaseProducts() {
		axios
			.get(`${BACKEND_URL}/products`, {
				headers: {
					token: localStorage.getItem("Token")
				}
			})
			.then((data) => {
				initialState.Products = data.data;
				setProductLoading(false);
			});
	}

	useEffect(() => {
		if (initialState.Products.length <= 0) {
			GetAllPurchaseProducts();
		}
	}, []);

	function isProductinCart(id) {
		let response = { text: "Add to Cart", Quantity: 1, inCart: false };
		state.selected.filter((a) => {
			if (a.id == id) {
				response = {
					text: "Update in Cart",
					Quantity: a.Quantity,
					inCart: true
				};
			} else {
				response = { text: "Add to Cart", Quantity: 1, inCart: false };
			}
		});
		return response;
	}
	function clearSelected() {
		dispatch({
			type: "CLEAR_SELECTED"
		});
	}

	function setSelected(id) {
		dispatch({
			type: "SET_SELECTED",
			payload: { id }
		});
	}

	function setQuantity(id, Quantity) {
		dispatch({
			type: "SET_QUANTITY",
			payload: { id, Quantity }
		});
	}

	function removeSelected(id) {
		dispatch({
			type: "REMOVE_SELECTED",
			payload: id
		});
	}

	// const socket = io(`${BACKEND_URL}`);

	// useEffect(() => {
	// 	socket.on("connect", () => {
	// 		state.Products.map((pro, i) => {
	// 			socket.on(`Quantity-${pro.id}`, (msg) => {
	// 				pro.QuantityAvailable = msg;
	// 				state.Products.filter((a) => {
	// 					if (a.id == pro.id) {
	// 						a.QuantityAvailable = msg;
	// 					}
	// 					initialState.Products = state.Products;
	// 				});
	// 			});
	// 		});
	// 	});
	// }, [socket]);



	return (
		<PurchaseContext.Provider
			value={{
				selected: state.selected,
				Products: state.Products,
				ProductLoading,
				GetAllPurchaseProducts,
				clearSelected,
				setSelected,
				setQuantity,
				removeSelected,
				isProductinCart,
			}}
		>
			{children}
		</PurchaseContext.Provider>
	);
};

export default PurchaseProvider;
